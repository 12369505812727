var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container tenant-project-page" },
    [
      _c(
        "el-row",
        { staticClass: "button-line" },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "r-button",
                { attrs: { plain: "" }, on: { click: _vm.openModalBox } },
                [
                  _c("i", { staticClass: "el-icon-plus button-icon" }),
                  _vm._v("\n        新增\n      "),
                ]
              ),
              _c("r-button", { on: { click: _vm.searchCondition } }, [
                _c("i", { staticClass: "el-icon-refresh-right button-icon" }),
                _vm._v("\n        刷新\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("FinalTable", {
        ref: "finalTableRef",
        attrs: { datas: _vm.dataset },
        on: { tableEventHandler: _vm.tableEventHandler },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            width: "40%",
            top: "6vh",
            visible: _vm.addModalBox,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addModalBox = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [_c("title-icon"), _vm._v(_vm._s(_vm.title) + " ")],
            1
          ),
          _c(
            "el-form",
            {
              ref: "projectForm",
              attrs: {
                "label-position": "top",
                rules: _vm.projectFormRules,
                model: _vm.form,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "projectName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.setCode()
                              },
                            },
                            model: {
                              value: _vm.form.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectName", $$v)
                              },
                              expression: "form.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目编码", prop: "projectCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "20",
                              "show-word-limit": true,
                              disabled: _vm.readProjectCode,
                              placeholder: _vm.$t("commons.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.projectCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectCode", $$v)
                              },
                              expression: "form.projectCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属公司", prop: "companyId" } },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "show-all-levels": false,
                              options: _vm.companyList,
                              props: _vm.cascaderPros,
                            },
                            model: {
                              value: _vm.form.companyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyId", $$v)
                              },
                              expression: "form.companyId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.project.city"),
                            prop: "cityName",
                          },
                        },
                        [
                          _c("el-autocomplete", {
                            staticClass: "inline-input",
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              "fetch-suggestions": _vm.querySearch,
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            on: {
                              clear: function ($event) {
                                return _vm.clearCity()
                              },
                              select: _vm.handleSelect,
                            },
                            model: {
                              value: _vm.form.cityName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cityName", $$v)
                              },
                              expression: "form.cityName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.project.address") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "80",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 11 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("base.project.floorSpace"),
                            prop: "floorSpace",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "12",
                              "show-word-limit": true,
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.floorSpace,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "floorSpace", $$v)
                              },
                              expression: "form.floorSpace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 11, offset: 2 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.project.location") } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: {
                                readonly: true,
                                placeholder: _vm.$t("commons.selectPlease"),
                              },
                              model: {
                                value: _vm.form.location,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "location", $$v)
                                },
                                expression: "form.location",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-location",
                                },
                                on: { click: _vm.openMapSelect },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.project.label") } },
                        [
                          _c("tag-bind", {
                            attrs: {
                              tagValueList: _vm.form.tagList,
                              tagType: "project",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("base.project.desc") } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "160",
                              "show-word-limit": true,
                              type: "textarea",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelProjectListSave } }, [
                _vm._v(_vm._s(_vm.$t("commons.cancel"))),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.saveLoading,
                      expression: "saveLoading",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.saveNewProjectList },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "hidden",
            attrs: { type: "hidden" },
            model: {
              value: _vm.hiddenData,
              callback: function ($$v) {
                _vm.hiddenData = $$v
              },
              expression: "hiddenData",
            },
          }),
        ],
        2
      ),
      _c("map-select", {
        attrs: {
          position: _vm.position,
          cityName: _vm.form.cityName,
          actions: _vm.isAction,
          mapSelectDialog: _vm.mapSelectDialog,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }