<template>
  <div class="app-container tenant-project-page">
    <el-row class="button-line">
      <el-col :span="8">
        <r-button plain @click="openModalBox">
          <i class="el-icon-plus button-icon"></i>
          新增
        </r-button>
        <r-button @click="searchCondition">
          <i class="el-icon-refresh-right button-icon"></i>
          刷新
        </r-button>
      </el-col>
    </el-row>
    <FinalTable
      ref="finalTableRef"
      :datas="dataset"
      @tableEventHandler="tableEventHandler"
    />
    <!-- 添加/编辑项目弹出框 -->
    <el-dialog
      :close-on-click-modal="false"
      width="40%"
      top="6vh"
      :visible.sync="addModalBox"
      :destroy-on-close="true"
    >
      <template slot="title"> <title-icon />{{ title }} </template>
      <el-form
        label-position="top"
        ref="projectForm"
        :rules="projectFormRules"
        :model="form"
      >
        <el-row>
          <el-col :span="11">
            <el-form-item label="项目名称" prop="projectName">
              <el-input
                v-model="form.projectName"
                maxlength="20"
                :show-word-limit="true"
                @input="setCode()"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item label="项目编码" prop="projectCode">
              <el-input
                v-model="form.projectCode"
                maxlength="20"
                :show-word-limit="true"
                :disabled="readProjectCode"
                :placeholder="$t('commons.pleaseInput')"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="所属公司" prop="companyId">
              <el-cascader
                v-model="form.companyId"
                :show-all-levels="false"
                :options="companyList"
                :props="cascaderPros"
                style="width: 100%"
              ></el-cascader>
              <!-- <el-select v-model="form.companyId" :style="{ width: '100%' }">
                <el-option
                  v-for="item in companyList"
                  :label="item.companyName"
                  :value="item.companyId"
                  :key="item.companyId"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item :label="$t('base.project.city')" prop="cityName">
              <el-autocomplete
                style="width: 100%"
                class="inline-input"
                v-model="form.cityName"
                clearable
                @clear="clearCity()"
                @select="handleSelect"
                :fetch-suggestions="querySearch"
                :placeholder="$t('commons.pleaseInput')"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('base.project.address')">
              <el-input
                v-model="form.address"
                maxlength="80"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item
              :label="$t('base.project.floorSpace')"
              prop="floorSpace"
            >
              <el-input
                v-model="form.floorSpace"
                maxlength="12"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="2">
            <el-form-item :label="$t('base.project.location')">
              <el-input
                :readonly="true"
                v-model="form.location"
                class="input-with-select"
                :placeholder="$t('commons.selectPlease')"
              >
                <el-button
                  slot="append"
                  @click="openMapSelect"
                  icon="el-icon-location"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('base.project.label')">
              <tag-bind :tagValueList="form.tagList" tagType="project" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('base.project.desc')">
              <el-input
                v-model="form.description"
                maxlength="160"
                :show-word-limit="true"
                type="textarea"
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelProjectListSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          v-loading="saveLoading"
          @click="saveNewProjectList"
          >{{ $t("commons.save") }}</el-button
        >
      </div>
      <el-input type="hidden" class="hidden" v-model="hiddenData"></el-input>
    </el-dialog>

    <map-select
      :position="position"
      :cityName="form.cityName"
      :actions="isAction"
      :mapSelectDialog="mapSelectDialog"
    />
  </div>
</template>

<script>
import {
  getProjectListPage,
  updateProjectList,
  deleteProjectList,
  getCompanyCascader,
  insertProject,
  getOwnProject,
} from "@/api/business/base/tenant/project";
import Pagination from "@/components/Pagination";
import TagBind from "@/components/TagBind";
import pinyin from "js-pinyin";
import { validateNumber } from "@/utils/validate";
import cityData from "../city.json";
import MapSelect from "@/components/MapSelect";
import FinalTable from "@/components/FinalTable";
import { listToTree } from "@/utils/tree";
import { GenNonDuplicateID } from "@/utils/utils.js";

export default {
  name: "projectManager",
  components: { Pagination, TagBind, MapSelect, FinalTable },
  data() {
    return {
      cascaderPros: {
        value: "companyId",
        label: "label",
        children: "children",
        emitPath: false,
      },
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "companyName", label: "所属公司名称", width: "" },
          { prop: "projectName", label: "项目名称", width: "" },
          { prop: "projectCode", label: "项目编码", width: "" },
          { prop: "cityName", label: "所在城市", width: "" },
          { prop: "address", label: "详细地址 ", width: "" },
          { prop: "floorSpace", label: "占地面积(㎡)", width: "" },
          { prop: "operation", label: "操作", width: "90" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          projectName: {
            type: "input",
            label: "项目名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入项目名称",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#F35555",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      saveLoading: false,
      position: null,
      mapSelectDialog: false,
      isAction: true,
      isActive: true,
      readProjectCode: false,
      total: 0,
      list: null,
      listLoading: false,
      companyList: [],
      hiddenData: "",
      listQuery: {
        //分页参数
        current: 1,
        rowCount: 10,
      },
      form: {
        projectId: null,
        projectName: null,
        projectCode: null,
        companyId: null,
        cityName: null,
        address: null,
        floorSpace: null,
        location: null,
        description: null,
        tagList: [],
      },
      projectFormRules: {
        projectName: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
        projectCode: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
        companyId: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
        cityName: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
        floorSpace: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (value && !validateNumber(value)) {
                callback(
                  new Error(this.$t("base.monitor.validMessage.number"))
                );
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      //默认设置模态框不可见
      addModalBox: false,
      title: null,
    };
  },
  watch: {
    listLoading: {
      handler(val) {
        this.$refs.finalTableRef && this.$refs.finalTableRef.loadingToggle(val);
      },
      deep: true,
    },
  },
  created() {
    this.searchCondition();
    this.getCompanyList();
    this.citys = cityData;
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.getProjectListsPage();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getProjectListsPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editProject(datas.row.projectId);
            break;
          case "delete":
            this.deleteProjectList(datas.row.projectId, datas.row.projectName);
            break;
        }
      }
    },
    selectMapPosition(position) {
      this.form.location = position.lng + "," + position.lat;
      this.mapSelectDialog = false;
    },

    openMapSelect() {
      if (this.form.cityName) {
        //设置为可操作
        this.isAction = true;
        if (this.form.location != null) {
          this.position = {
            lng: parseFloat(this.form.location.split(",")[0]),
            lat: parseFloat(this.form.location.split(",")[1]),
          };
        } else {
          this.position = null;
        }
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("base.project.pleaseSelectCity"),
          type: "info",
        });
      }
    },

    openMapLocation(row) {
      if (row.location) {
        //设置为不可操作
        this.isAction = false;
        this.position = {
          lng: parseFloat(row.location.split(",")[0]),
          lat: parseFloat(row.location.split(",")[1]),
        };
        this.mapSelectDialog = true;
      } else {
        this.$message({
          message: this.$t("base.project.locationNotExist"),
          type: "info",
        });
      }
    },

    clearCity() {
      this.form.cityName = null;
      this.form.location = null;
    },
    handleSelect(item) {
      this.form.cityName = item.value;
      this.form.location = null;
    },

    querySearch(queryString, cb) {
      let citys = this.citys;
      let results = queryString
        ? citys.filter(this.createFilter(queryString))
        : citys;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (city) => {
        return (
          city.label.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },

    setCode() {
      // 编辑项目时，编码不可修改
      if (!this.readProjectCode) {
        let name = this.form.projectName;
        this.form.projectCode = pinyin.getCamelChars(name);
      }
    },
    //清除条件搜索输入框
    clearSearchInput() {
      this.listQuery.projectName = "";
    },
    //条件搜索
    searchCondition() {
      this.listQuery.current = 1;
      this.getProjectListsPage();
    },
    //开启模态框
    openModalBox() {
      this.title = this.$t("base.project.add");
      this.clearForm();
      this.readProjectCode = false;
      this.hiddenData = "";
      this.addModalBox = true;
    },
    //关闭模态框
    cancelProjectListSave() {
      this.$refs.projectForm.resetFields();
      this.addModalBox = false;
    },
    //查询公司列表
    getCompanyList() {
      getCompanyCascader().then((res) => {
        this.companyList = listToTree(
          res.map((item) => {
            item.label = item.companyName || item.nodeName;
            return item;
          }),
          "nodeId",
          "parentNodeId"
        ).map((item) => {
          if (item.children === null) {
            item.children = [];
          }
          if (!item.companyId) {
            item.companyId = GenNonDuplicateID();
          }
          return item;
        });
        console.log("this.companyList", this.companyList);
      });
    },
    //加载table
    getProjectListsPage() {
      this.listLoading = true;
      getProjectListPage(this.listQuery)
        .then((res) => {
          this.listLoading = false;
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch((error) => {
          this.listLoading = false;
          console.log(`查询失败，原因=> ${error}`);
        });
    },
    //修改
    editProject(projectId) {
      this.title = this.$t("base.project.edit");
      this.readProjectCode = true;
      this.clearForm();
      this.hiddenData = projectId;
      getOwnProject(projectId).then((res) => {
        this.addModalBox = true;
        this.form.projectName = res.projectName;
        this.form.projectCode = res.projectCode;
        this.form.companyId = res.companyId;
        this.form.cityName = res.cityName;
        this.form.address = res.address;
        this.form.floorSpace = res.floorSpace;
        this.form.location = res.location;
        this.form.description = res.description;
        this.form.tagList = res.tagList;
      });
    },
    //删除
    deleteProjectList(projectId, projectName) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteProjectList({ projectId: projectId, projectName: projectName })
            .then(() => {
              //成功提示
              this.$message({
                message: this.$t("message.deleteSuccess"),
                type: "success",
              });
              //删除成功后重新渲染table
              this.searchCondition();
            })
            .catch((error) => {
              console.log(`删除失败，原因 => ${error}`);
            });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    //保存
    saveNewProjectList() {
      if (this.saveLoading) return;
      this.$refs.projectForm.validate((v) => {
        if (v) {
          this.saveLoading = true;
          //去重复
          this.form.tagList = Array.from(new Set(this.form.tagList));
          if (this.hiddenData) {
            this.form.projectId = this.hiddenData;
            updateProjectList(this.form)
              .then(() => {
                //关闭模态框
                this.addModalBox = false;
                //修改成功的提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //重新加载table
                this.getProjectListsPage();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          } else {
            insertProject(this.form)
              .then(() => {
                //关闭模态框
                this.addModalBox = false;
                //保存成功的提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                //重新加载table
                this.getProjectListsPage();
              })
              .finally(() => {
                this.saveLoading = false;
              });
          }
        }
      });
    },
    //清空
    clearForm() {
      this.form.projectId = null;
      this.form.projectName = null;
      this.form.projectCode = null;
      this.form.companyId = null;
      this.form.cityName = null;
      this.form.address = null;
      this.form.floorSpace = null;
      this.form.location = null;
      this.form.description = null;
      this.form.tagList = [];
    },
  },
};
</script>

<style scoped lang="less">
.tenant-project-page {
  .button-line {
    margin-bottom: 20px;
    .button-icon {
      margin-right: 4px;
    }
  }
}
::v-deep .input-style .el-input__inner {
  padding-right: 69px;
}

::v-deep .input-style .el-input__suffix {
  background: #fff;
  height: 95%;
  top: 1px;
}
</style>
